import {
    ColDef,
    ColumnMovedEvent,
    ColumnResizedEvent,
    ColumnVisibleEvent,
    GetContextMenuItemsParams,
    GetMainMenuItemsParams,
    GridApi,
    RowClassRules,
    SortChangedEvent
} from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';

export abstract class AGTableBase {

    abstract update(): void;

    mutationObserver: MutationObserver;
    paginationPageSize: number = 20;
    sizes: number[] = [20, 50, 100];
    domLayout = "autoHeight";
    gridApi: GridApi;
    totalRowsCount: number = 0;
    currentPageNumber: number = 1;
    sortState: string[] = [];

    columnChange$ = new Subject<ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent | SortChangedEvent>();
    customIcons: { [key: string]: Function | string; } = {
        menu: '<span class="icon-settings fs-3 text-neutral-n7 cp"></span>',
    }

    defaultColDef: ColDef = {
        flex: 1,
        resizable: true,
        sortable: false,
        minWidth: 20,
        autoHeaderHeight: true,
        wrapHeaderText: true,
        wrapText: true,
        suppressHeaderMenuButton: true,
        mainMenuItems: [
            'autoSizeAll',
            'resetColumns',
        ]
    };

    rowClassRules: RowClassRules = {};

    onPageChange(event) {
        if (event === this.currentPageNumber) {
            return;
        }
        this.currentPageNumber = event;
        this.update();
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.update();
    }

    onSortChange(event: SortChangedEvent) {
        const columnState = this.gridApi.getColumnState();
        this.sortState = columnState.map(c => c.sort ? `${c.colId},${c.sort}` : null);
        this.update();
    }

    onColumnMoved(event: ColumnMovedEvent) {
        this.columnChange$.next(event);
    }

    onColumnResized(event: ColumnResizedEvent) {
        this.columnChange$.next(event);
    }

    onColumnVisible(event: ColumnVisibleEvent) {
        this.columnChange$.next(event);
    }

    getMainMenuItems(params: GetMainMenuItemsParams) {
        return params.defaultItems.slice(1); // Hide Autosize this column ...
    }

}
